import React, { useEffect, useState } from "react";
import instancePromise from '../axiosInstance';
import Swal from 'sweetalert2'
import { useCookies } from 'react-cookie';
import { useNavigate } from "react-router-dom"
import { handleError } from '../Global'
import '../../table.css'
import './Comandes.css'

export default function Comandes() {
    const [cookies] = useCookies(['auth_token']);
    const navigate = useNavigate();

    document.title = "Comandes - Pantà de Riudecanyes"

    let [teComuners, setTeComuners] = useState(true)
    let [comandes, setComandes] = useState([]);
    let [carregant, setCarregant] = useState(true)
    let [horarisDisponibles, setHorarisDisponibles] = useState(false)

    const temporadaActual = new Date().getMonth() + 1 >= 10 ? new Date().getFullYear() : new Date().getFullYear() - 1
    const temporades = [];
    for (let i = temporadaActual; i >= 2000; i--) {
        temporades.push(i);
    }

    const [temporadaSelected, setTemporadaSelected] = useState(
        localStorage.getItem('temporadaSelected') || temporadaActual
    );
    useEffect(() => {
        localStorage.setItem('temporadaSelected', temporadaSelected);
    }, [temporadaSelected]);


    useEffect(() => {
        const temporadaSelect = document.getElementById("temporadaSelect");
        const conteTemporadaSelected = Array.from(temporadaSelect.options).find(
            (opcion) => opcion.value === temporadaSelected
        );
        if (conteTemporadaSelected) {
            temporadaSelect.value = temporadaSelected;
        }

        loadComandes();
        loadPersones();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cookies, navigate])

    function loadComandes() {
        setComandes([])
        setCarregant(true)
        setTemporadaSelected(document.getElementById("temporadaSelect").value);

        document.getElementById("temporadaSelect").disabled = true

        let temporadaSelected = document.getElementById("temporadaSelect").value
        instancePromise.then(instance => {
            instance
                .get(`/comanda/temporada/` + temporadaSelected, {
                    headers: {
                        'Authorization': 'Bearer ' + cookies.auth_token
                    }
                })
                .then(response => {
                    setComandes(response.data)
                    setCarregant(false)
                    document.getElementById("temporadaSelect").disabled = false
                })
                .catch(error => {
                    handleError(error, navigate)
                });
        });
        if (parseInt(temporadaSelected) !== temporadaActual) {
            setHorarisDisponibles(true)
        } else {
            instancePromise.then(instance => {
                instance
                    .get(`/admin/horaris_disponibles`)
                    .then(response => {
                        setHorarisDisponibles(response.data)
                    })
                    .catch(error => {
                        handleError(error, navigate)
                    });
            });
        }
    }

    function loadPersones() {
        instancePromise.then(instance => {
            instance
                .get(`/usuari/persona`, {
                    headers: {
                        'Authorization': 'Bearer ' + cookies.auth_token
                    }
                })
                .then(response => {
                    setTeComuners(response.data.length !== 0)
                })
                .catch(error => {
                    handleError(error, navigate)
                });
        });
    }

    function handleDetalls(id) {
        navigate("detalls", { state: { comanda: comandes[id] } })
    }

    function handleEditar(id) {
        navigate("editar", { state: { comanda: comandes[id] } })
    }

    function handleEliminar(id) {
        Swal.fire({
            title: 'Eliminar comanda?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'Cancel·lar',
            confirmButtonText: '  Sí  '
        }).then((result) => {
            if (result.value) {
                instancePromise.then(instance => {
                    instance
                        .delete(`/comanda/` + comandes[id].codi_solicitud + `/` + comandes[id].codi_comanda + `/` + comandes[id].tipusAigua.tipus_aigua, {
                            headers: {
                                'Authorization': 'Bearer ' + cookies.auth_token
                            }
                        })
                        .then(() => {
                            Swal.fire(
                                'Eliminat!',
                                'La teva comanda s\'ha eliminat correctament',
                                'success'
                            )
                            loadComandes()
                        })
                        .catch(error => {
                            handleError(error, navigate)
                        });
                });
            }
        })
    }

    function handleHorari(id) {
        navigate("horari", { state: { comanda: comandes[id] } })
    }


    return (
        <div className="content-wrapper">
            <section className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                            <h1>Comandes</h1>
                        </div>
                        <div className="col-sm-6">
                            <ol className="breadcrumb float-sm-right">
                                <li className="breadcrumb-item"><a href="/">Inici</a></li>
                                <li className="breadcrumb-item active">Comandes</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </section>

            <section className="content">
                {!teComuners ? (
                    <div className="alert alert-info">
                        <h5><i class="icon fas fa-info"></i>Per crear una nova comanda d'aigua cal afegir un comuner.</h5>
                        <div>Pot afegir un comuner fent clic al següent <b><a href="/afegir-persona">enllaç</a></b>.</div>
                    </div>
                ) : (<></>
                    //<div className="card card-primary card-outline">
                    //    <div className="card-body">
                    //        <button className="btn btn-primary" onClick={() => navigate("crear")}>
                    //            <h4><i className="fas fa-plus-circle" /><span> Nova comanda</span></h4>
                    //        </button>
                    //    </div>
                    //</div>
                )}

                {/*<em>*/}
                {/*    <div className="alert bg-orange">*/}
                {/*        <h4><i class="icon fas fa-exclamation-triangle"></i>Finalment aquesta temporada no hi haurà reg d'emergència.</h4>*/}
                {/*        <h6>Per a més informació feu clic al següent <a href="https://pantaderiudecanyes.cat/2023/07/20/la-comunitat-de-regants-del-panta-de-riudecanyes-anuncia-que-no-es-possible-el-reg-demergencia/" target="_blank" rel="noreferrer">enllaç</a></h6>*/}
                {/*    </div>*/}
                {/*</em>*/}

                <div className="card">
                    <div className="card-header bg-dark">
                        <h4>Comandes realitzades - Temporada
                            <select id="temporadaSelect" className="ml-2 bg-dark" onChange={loadComandes}>
                                {temporades.map((temporada, index) => (
                                    <option key={index} value={temporada}>{temporada}-{temporada + 1}</option>
                                ))}
                            </select>
                        </h4>
                    </div>
                    {carregant ? (
                        <main className="hold-transition login-page">
                            <i className="fas fa-3x fa-sync-alt fa-spin"></i>
                            <div className="text-bold pt-2">Carregant...</div>
                        </main>
                    ) : (
                        <>
                            <div className="card-body p-0">
                                <table className="resp">
                                    <thead>
                                        <tr>
                                            <th>Comuner</th>
                                            <th>Tipus de comanda</th>
                                            <th>Presa</th>
                                            <th className="centre">Hores</th>
                                            {horarisDisponibles ? (<th className="centre">Horari</th>) : (<></>)}
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody className="colComandes">
                                        {comandes.length === 0 ? (
                                            <tr>
                                                <td colSpan={11}>Cap comanda realitzada</td>
                                            </tr>
                                        ) : (
                                            comandes.map((comanda, index) => (
                                                <tr key={index}>
                                                    <td>{comanda.persona.nom + " " + comanda.persona.cognom1 + " " + comanda.persona.cognom2 + " (" + comanda.comuner.codi_comuner + ")"}</td>
                                                    {comanda.aCompteOExtra === 2 ? (
                                                        <td>{comanda.tipusAigua.descripcio + " (A compte)"}</td>
                                                    ) : (
                                                        <>
                                                            {comanda.aCompteOExtra === 3 ? (
                                                                <td>{comanda.tipusAigua.descripcio + " (Extra)"}</td>
                                                            ) : (
                                                                <td>{comanda.tipusAigua.descripcio}</td>)}
                                                        </>)}
                                                    {comanda.presa !== null ? (
                                                        <td>{comanda.presa.nom}</td>
                                                    ) : (
                                                        <td></td>)}
                                                    <td className="centre">{comanda.hores}</td>
                                                    {horarisDisponibles ? (
                                                        <td className="centre">
                                                            {comanda.horaris.length !== 0 ? (
                                                                <>
                                                                    <button type="button" className="btn btn-success btn-sm m-1" onClick={() => handleHorari(index)}>
                                                                        <i className="fas fa-clock" />
                                                                    </button>
                                                                </>
                                                            ) : (<div>{comanda.agrupacio !== null ? "Agrupació" : ""}</div>)}
                                                        </td>
                                                    ) : (<></>)}
                                                    <td className="text-right">
                                                        {comanda.modificable ? (
                                                            <>
                                                                <button type="button" className="btn btn-danger btn-sm m-1" onClick={() => handleEliminar(index)}>
                                                                    <i className="fas fa-trash" />
                                                                    <span> Eliminar</span>
                                                                </button>
                                                                <button type="button" className="btn btn-info btn-sm m-1" onClick={() => handleEditar(index)}>
                                                                    <i className="fas fa-pencil-alt" />
                                                                    <span> Modificar</span>
                                                                </button>
                                                            </>
                                                        ) : (<></>)}
                                                        <button type="button" className="btn btn-primary btn-sm m-1 col-offset-4" onClick={() => handleDetalls(index)}>
                                                            <i className="fas fa-eye" />
                                                            <span> Veure</span>
                                                        </button>
                                                    </td>
                                                </tr>
                                            )))}
                                    </tbody>
                                </table>
                            </div>
                        </>
                    )}
                </div>
            </section>
        </div >
    );
}