import React, { useState, useEffect } from "react";
import instancePromise from '../axiosInstance';
import { useNavigate, useLocation } from "react-router-dom"
import { useCookies } from 'react-cookie';
import Swal from 'sweetalert2'
import { alerta, handleError, handleInvalidInput, invalidInputMessage, parseJwt } from '../Global'

export default function Comuner() {
    const comuner = useLocation().state.comuner
    const [cookies] = useCookies(['auth_token']);

    document.title = "Dades del comuner - Pantà de Riudecanyes"

    const navigate = useNavigate();
    let [edit, setEdit] = useState(false)
    let [comunerInfoSensible, setComunerInfoSensible] = useState([])

    useEffect(() => {
        instancePromise.then(instance => {
            instance
                .get('/usuari/comuner-info?codiComuner=' + comuner.comuner.codi_comuner, {
                    headers: {
                        'Authorization': 'Bearer ' + cookies.auth_token
                    }
                })
                .then(response => {
                    let comunerInfo = response.data
                    comunerInfo.iban = comunerInfo.iban.replace(/\s/g, '')
                    setComunerInfoSensible(comunerInfo)
                })
                .catch(error => {
                    handleError(error, navigate)
                });
        });
    }, [cookies, navigate, comuner])

    function checkIban(iban) {
        // Extrae el código del país y los dígitos de control
        let pais = iban.substring(0, 2);
        let digitosControl = iban.substring(2, 4);

        // Mueve los dígitos de control al final del IBAN
        iban = iban.substring(4) + pais + digitosControl;

        // Convierte letras a números
        let numeros = '';
        for (let i = 0; i < iban.length; i++) {
            let charCode = iban.charCodeAt(i);
            if (charCode >= 65 && charCode <= 90) {
                numeros += (charCode - 55).toString();
            } else {
                numeros += iban.charAt(i);
            }
        }

        // Realiza la división
        let resto = '';
        for (let i = 0; i < numeros.length; i++) {
            let digito = parseInt(resto + numeros.charAt(i), 10);
            resto = digito % 97;
        }

        return resto === 1
    }

    function handleSubmit(event) {
        event.preventDefault();

        let iban = document.getElementById('iban').value
        iban = iban.replace(/\s/g, '')

        let titularCompte = document.getElementById('titular').value

        if (!checkIban(iban)) {
            alerta("IBAN invàlid")
            return
        }

        Swal.fire({
            title: 'Segur que es vol modificar les dades del comuner?',
            text: 'En cas afirmatiu es procedirà a descarregar un fitxer certificant aquesta modificació',
            icon: 'info',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'Cancel·lar',
            confirmButtonText: '  Sí  ',
        }).then((result) => {
            if (result.value) {
                instancePromise.then(instance => {
                    instance
                        .post('/usuari/comuner-info',
                            {
                                codiComuner: comuner.comuner.codi_comuner,
                                iban: iban,
                                titularCompte: titularCompte
                            },
                            {
                                headers: {
                                    'Authorization': 'Bearer ' + cookies.auth_token
                                },
                                responseType: 'arraybuffer'
                            })
                        .then(response => {

                            const newComunerInfoSensible = { ...comunerInfoSensible };
                            newComunerInfoSensible.iban = iban;
                            newComunerInfoSensible.titularCompte = titularCompte;
                            setComunerInfoSensible(newComunerInfoSensible);

                            const byteArray = new Uint8Array(response.data);
                            const file = new Blob([byteArray], { type: "application/pdf" });
                            const fileURL = URL.createObjectURL(file);

                            const link = document.createElement('a');
                            link.href = fileURL;
                            const dateString = new Date(Date.now()).toISOString().replace(/[-:.TZ]/g, "");
                            link.download = `${comuner.comuner.codi_comuner}${dateString}.pdf`;
                            document.body.appendChild(link);
                            link.click();
                            document.body.removeChild(link);

                            Swal.fire(
                                'Actualitzat!',
                                'Les dades del comuner s\'han actualitzat correctament',
                                'success'
                            )
                        })
                        .catch(error => {
                            console.log(error)
                            handleError(error, navigate)
                        });
                });

                setEdit(!edit)
            }
        })
    }

    return (
        <main className="hold-transition login-page">
            <div className="login-box">
                <div className="card card-outline card-primary">
                    <div className="card-header text-center">
                        <a href="/" className="h1"><b>Pantà de Riudecanyes</b></a>
                    </div>
                    <div className="card-body">
                        {parseJwt(cookies.auth_token).assuranceLevel >= 1 && comuner.pertanyComuner ? (
                            <>
                                {/*<h4 className="text-center">{comuner.persona.nom + " " + comuner.persona.cognom1 + " " + comuner.persona.cognom2 + " (" + comuner.comuner.codi_comuner + ")"}</h4>*/}
                                <p className="login-box-msg">A continuació es mostren les dades personals del comuner</p>

                                <form>

                                    <div className="mb-3">
                                        <h5>Comuner</h5>
                                        <div>{comuner.persona.nom + " " + comuner.persona.cognom1 + " " + comuner.persona.cognom2 + " (" + comuner.comuner.codi_comuner + ")"}</div>
                                    </div>

                                    {edit ? (
                                        <>
                                            <div className="mb-3">
                                                <h5>IBAN</h5>
                                                <div className="input-group mb-3">
                                                    <input type="text" id="iban" className="form-control" placeholder="IBAN" defaultValue={comunerInfoSensible.iban} required title={invalidInputMessage} onInput={e => e.target.setCustomValidity('')} onInvalid={handleInvalidInput} />
                                                    <div className="input-group-append">
                                                        <div className="input-group-text">
                                                            <span className="fas fa-credit-card"></span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="mb-3">
                                                <h5>Titular del compte</h5>
                                                <div className="input-group mb-3">
                                                    <input type="text" id="titular" className="form-control" placeholder="Titular del compte" defaultValue={comunerInfoSensible.titularCompte} required title={invalidInputMessage} onInput={e => e.target.setCustomValidity('')} onInvalid={handleInvalidInput} />
                                                    <div className="input-group-append">
                                                        <div className="input-group-text">
                                                            <span className="fas fa-user"></span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div style={{ display: "flex", flexDirection: "row-reverse" }}>
                                                <button type="button" className="btn btn-danger ml-3" onClick={() => setEdit(!edit)}>
                                                    <i className="fas fa-times" />
                                                    <span> Cancel·lar</span>
                                                </button>
                                                <button type="button" className="btn btn-primary" onClick={handleSubmit}>
                                                    <i className="fas fa-check" />
                                                    <span> Guardar</span>
                                                </button>
                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            <div className="mb-3">
                                                <h5>IBAN</h5>
                                                {comunerInfoSensible.iban !== "" && comunerInfoSensible.iban !== null ? (
                                                    <div>{comunerInfoSensible.iban}</div>
                                                ) : (
                                                    <div>No indicat</div>
                                                )}
                                            </div>
                                            <div className="mb-3">
                                                <h5>Titular del compte</h5>
                                                {comunerInfoSensible.titularCompte !== "" && comunerInfoSensible.titularCompte !== null ? (
                                                    <div>{comunerInfoSensible.titularCompte}</div>
                                                ) : (
                                                    <div>No indicat</div>
                                                )}
                                            </div>
                                            <div style={{ display: "flex", flexDirection: "row-reverse" }}>
                                                <button type="button" className="btn btn-primary ml-3" onClick={() => navigate(-1)}>Enrere</button>
                                                <button type="button" className="btn btn-info" onClick={() => setEdit(!edit)}>
                                                    <i className="fas fa-pen" />
                                                    <span> Modificar</span>
                                                </button>
                                            </div>
                                        </>
                                    )}
                                </form>
                            </>
                        ) : (
                            <>
                                <p className="login-box-msg">Sense permissos suficients per modificar la informació personal del comuner</p>
                                <div>Cal iniciar sessió amb idcat, certificat o cl@ve</div>
                                <div style={{ display: "flex", flexDirection: "row-reverse" }}>
                                    <button type="button" className="btn btn-primary" onClick={() => navigate(-1)}>Enrere</button>
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </div>
        </main>
    );
}