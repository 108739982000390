import React, { useState, useEffect } from "react";
import instancePromise from '../axiosInstance';
import Swal from 'sweetalert2'
import { useCookies } from 'react-cookie';
import { useNavigate } from "react-router-dom"
import jwt_decode from "jwt-decode";
import { invalidInputMessage, handleInvalidInput, handleInvalidContrasenya, handleError, alerta, parseJwt } from '../Global'
import "./Perfil.css"

export default function Perfil() {
    const navigate = useNavigate();
    const [cookies, setCookie, removeCookie] = useCookies(['auth_token']);

    let [carregant, setCarregant] = useState(true)
    let [edit, setEdit] = useState(false)
    let [editarContrasenya, setEditarContrasenya] = useState(false)

    document.title = "Compte - Pantà de Riudecanyes"

    let [comuners, setComuners] = useState([])
    useEffect(loadComuners, [cookies, navigate])

    function loadComuners() {
        instancePromise.then(instance => {
            instance
                .get(`/usuari/persona`,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + cookies.auth_token
                        }
                    })
                .then(response => {
                    setComuners(response.data)
                    setCarregant(false)
                })
                .catch(error => {
                    handleError(error, navigate)
                });
        });
    }

    function handleUpdateProfile(event) {
        event.preventDefault()
        if (editarContrasenya && (document.getElementById("password").value !== document.getElementById("confirmPassword").value)) {
            alerta("Les contrasenyes no coincideixen")
            return
        }

        let btnGuardar = document.getElementById("btnGuardar")
        btnGuardar.disabled = true

        instancePromise.then(instance => {
            instance
                .post(`/usuari/update`,
                    {
                        username: document.getElementById("nom").value,
                        password: editarContrasenya ? document.getElementById("password").value : "",
                        confirmPassword: editarContrasenya ? document.getElementById("confirmPassword").value : ""
                    },
                    {
                        headers: {
                            'Authorization': 'Bearer ' + cookies.auth_token
                        }
                    })
                .then(response => {
                    btnGuardar.disabled = false

                    removeCookie('auth_token');
                    let decoded = jwt_decode(response.data);
                    let age = decoded.exp - decoded.iat;
                    setCookie('auth_token', response.data, { path: '/', maxAge: age });
                    mostrarDades()
                    Swal.fire(
                        'Actualitzat!',
                        'Compte actualitzat correctament',
                        'success'
                    )
                })
                .catch(error => {
                    btnGuardar.disabled = false
                    handleError(error, navigate)
                });
        });
    }

    function editarDades() {
        setEdit(true)
        document.getElementById("botonsGuardar").hidden = false
        document.getElementById("botoEditar").hidden = true
    }

    function mostrarDades() {
        setEdit(false)
        setEditarContrasenya(false)
        document.getElementById("botonsGuardar").hidden = true
        document.getElementById("botoEditar").hidden = false
    }

    return (
        <div className="content-wrapper">
            <section className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                            <h1>Compte</h1>
                        </div>
                        <div className="col-sm-6">
                            <ol className="breadcrumb float-sm-right">
                                <li className="breadcrumb-item"><a href="/">Inici</a></li>
                                <li className="breadcrumb-item active">Compte</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </section>
            <section className="content">
                <div className="card">
                    <div className="card-header bg-dark">
                        <h4>Dades Personals</h4>
                    </div>
                    <div className="card-body">
                        <form onSubmit={handleUpdateProfile}>
                            <div className="row mb-4">
                                {parseJwt(cookies.auth_token).method === "emailPassword" ? (
                                    <>
                                        <div className="col-12 col-lg-2 mb-2">
                                            Adreça electrònica
                                        </div>
                                        <div className="col-12 col-lg-10"><b>{parseJwt(cookies.auth_token).email}</b></div>
                                    </>
                                ) : (
                                    <>
                                        <div className="col-12 col-lg-2 mb-2">
                                            NIF
                                        </div>
                                        <div className="col-12 col-lg-10"><b>{parseJwt(cookies.auth_token).NIF}</b></div>
                                    </>
                                )}
                            </div>
                            <div className="row mb-4">
                                <div className="col-12 col-lg-2 mb-2">
                                    Nom
                                </div>
                                <div className="col-12 col-lg-10">
                                    {edit ? (
                                        <input type="text" id="nom" defaultValue={parseJwt(cookies.auth_token).unique_name} className="form-control-edited" required title={invalidInputMessage} onInput={e => e.target.setCustomValidity('')} onInvalid={handleInvalidInput} />
                                    ) : (<b>{parseJwt(cookies.auth_token).unique_name}</b>)}
                                </div>
                            </div>
                            {edit ? (
                                <>
                                    <div className="custom-control custom-checkbox mb-3">
                                        <input type="checkbox" className="custom-control-input" id="chkContrasenya" onClick={() => setEditarContrasenya(!editarContrasenya)} />
                                        <label className="custom-control-label" htmlFor="chkContrasenya">Canviar contrasenya</label>
                                    </div>
                                    {editarContrasenya ? (
                                        <>
                                            <div className="row mb-4">
                                                <div className="col-12 col-lg-2 mb-2">
                                                    Nova contrasenya
                                                </div>
                                                <div className="col-12 col-lg-10">
                                                    <input type="password" id="password" pattern=".{8,}" className="form-control-edited" required title={invalidInputMessage} onInput={e => { if (e.target.dataset.isHandlingInvalid) handleInvalidContrasenya(e) }} onInvalid={handleInvalidContrasenya} />
                                                </div>
                                            </div>
                                            <div className="row mb-4">
                                                <div className="col-12 col-lg-2 mb-2">
                                                    Confirmar contrasenya
                                                </div>
                                                <div className="col-12 col-lg-10">
                                                    <input type="password" id="confirmPassword" pattern=".{8,}" className="form-control-edited" required title={invalidInputMessage} onInput={e => { if (e.target.dataset.isHandlingInvalid) handleInvalidContrasenya(e) }} onInvalid={handleInvalidContrasenya} />
                                                </div>
                                            </div>
                                        </>
                                    ) : (<></>)}
                                </>
                            ) : (<></>)}
                            <div className="row">
                                <div className="col-2"></div>
                                <div className="form-control-edited text-right">
                                    <div id="botonsGuardar" hidden>
                                        <button type="submit" id="btnGuardar" className="btn btn-primary">Guardar canvis</button>
                                        <button type="button" className="btn btn-danger ml-3" onClick={mostrarDades}>Cancel·lar</button>
                                    </div>
                                    {parseJwt(cookies.auth_token).method === "emailPassword" ? (
                                        <button type="button" id="botoEditar" className="btn btn-info" onClick={editarDades}>
                                            <i className="fas fa-pen" />
                                            <span> Modificar dades</span>
                                        </button>
                                    ) : (<></>)}
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div className="card">
                    <div className="card-header bg-dark">
                        <h4>Comuners Gestionats</h4>
                    </div>
                    <div className="card-body">
                        {carregant ? (
                            <main className="hold-transition login-page" style={{ height: "auto" }}>
                                <i className="fas fa-3x fa-sync-alt fa-spin"></i>
                                <div className="text-bold pt-2">Carregant...</div>
                            </main>
                        ) : (<>
                            <div className="grid-container">
                                {comuners.length === 0 ? (
                                    <p>Cap comuner gestionat</p>
                                ) : (
                                    comuners.map((comuner, index) => (
                                        <div key={index} className="grid-item">
                                            <h5>{comuner.persona.nom + " " + comuner.persona.cognom1 + " " + comuner.persona.cognom2 + " (" + comuner.comuner.codi_comuner + ")"}</h5>

                                            {parseJwt(cookies.auth_token).assuranceLevel >= 1 && comuner.pertanyComuner ? (
                                                <button type="button" className="btn btn-primary btn-sm" onClick={() => navigate("comuner", { state: { comuner: comuners[index] } })}>
                                                    <i className="fas fa-eye" />
                                                    <span> Veure</span>
                                                </button>
                                            ) : (<></>)}
                                        </div>
                                    )))}
                            </div>
                            <div className="text-right mt-3">
                                <button className="btn btn-primary btn-lg" onClick={() => navigate("/afegir-persona")}>
                                    <i className="fas fa-plus-circle" />
                                    <span> Afegir comuner</span>

                                </button>
                            </div>
                        </>)}
                    </div>
                </div>
            </section>
        </div>
    );
}